/* -------------------------------------------------------------------------- *
 * Carousel *
 * -------------------------------------------------------------------------- *
 * Accessible carousel using the slick carousel plugin.
 * https://kenwheeler.github.io/slick/
 */
/* global $ */

const settings = {
    selector: '.js-carousel',
    slick: {
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1180,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 980,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 680,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
};

/* ------------- *
 * Module Export *
 * ------------- */

const Carousel = {
    selector: settings.selector,

    init(selector = settings.selector, options = settings.slick) {
        const carousels = document.querySelectorAll(selector);
        const carousels_arr = Array.from(carousels);
        carousels_arr.forEach((el) => {
            $(el).slick(options);
        });
    },
};
export default Carousel;
