/* -------------------------------------------------------------------------- *
 * JavaScript Modules *
 * -------------------------------------------------------------------------- */

import Toggle from './modules/Toggle';
import ToggleNav from './modules/ToggleNav';
import Carousel from './modules/Carousel';
import Slider from './modules/Slider';
import VideoModal from './modules/VideoModal';

/* -------------------------------------------------------------------------- *
 * Module Initialization *
 * -------------------------------------------------------------------------- */

Toggle.init();
ToggleNav.init();
Carousel.init();
Slider.init();

if (document.querySelector('.js-videoBtn') !== null) {
    VideoModal.init();
}
