/* -------------------------------------------------------------------------- *
 * Carousel works for homepage *
 * -------------------------------------------------------------------------- *
 * Accessible carousel using the slick carousel plugin.
 * https://kenwheeler.github.io/slick/
 */
/* global $ */
const settings = {
    selector: '.js-slider',
    slick: {
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        cssEase: 'linear',
        dots: false,
        fade: true,
        pauseOnHover: 'false',
    },
};

/* ------------- *
 * Module Export *
 * ------------- */

const Slider = {
    selector: settings.selector,
    init(selector = settings.selector, options = settings.slick) {
        const sliders = document.querySelectorAll(selector);
        const sliders_arr = Array.from(sliders);
        sliders_arr.forEach((el) => {
            $(el).slick(options);
        });
    },
};
export default Slider;
