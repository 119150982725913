/* -------------------------------------------------------------------------- *
 * Toggle Mobile Navigation Menus *
 * -------------------------------------------------------------------------- *
 * Toggles the display of a target element when clicking on a trigger element.
 * Settings and initial state are controlled by ARIA attributes and the state
 * class. Styling for both the trigger and target element should be adjusted
 * using the state class that is added to both elements on click.
 *
 * -------------------------- *
 * Open Button Attributes *
 * -------------------------- *
 * class="js-openNav"
 * aria-haspopup="true"
 * aria-controls="js-1"
 * aria-expanded="false"
 *
 * -------------------------- *
 * Close Button Attributes *
 * -------------------------- *
 * class="js-closeNav"
 * aria-haspopup="true"
 * aria-controls="js-1"
 * aria-expanded="false"
 *
 * ------------------------- *
 * Target Element Attributes *
 * ------------------------- *
 * id="js-1"
 * aria-hidden="true"
 */

const settings = {
    selectors: {
        open: '.js-openNav',
        close: '.js-closeNav',
    },
    state_class: 'is-active',
};

class ToggleNavButton {
    constructor(el) {
        // Use ARIA attributes for settings
        const aria_expanded = el.getAttribute('aria-expanded');
        const aria_controls = el.getAttribute('aria-controls');

        // Object
        this.open_el = el;
        this.target_el = document.getElementById(aria_controls);
        this.close_el = this.target_el.querySelector(settings.selectors.close);
        this.toggled = aria_expanded === 'true';

        // Show nav on click
        this.open_el.addEventListener('click', (e) => {
            e.preventDefault();
            this.toggleNav();
        });

        // Hide nav on click
        this.close_el.addEventListener('click', (e) => {
            e.preventDefault();
            this.toggleNav();
        });
    }

    toggleNav() {
        // Update open button state
        this.open_el.classList.toggle(settings.state_class);
        this.open_el.setAttribute('aria-expanded', !this.toggled);

        // Update close button state
        this.close_el.classList.toggle(settings.state_class);
        this.close_el.setAttribute('aria-expanded', !this.toggled);

        // Update nav state
        this.target_el.classList.toggle(settings.state_class);
        this.target_el.setAttribute('aria-hidden', this.toggled);

        // Update object state
        this.toggled = !this.toggled;
    }
}

/* ------------- *
 * Module Export *
 * ------------- */

const ToggleNav = {
    init() {
        const buttons = document.querySelectorAll(settings.selectors.open);
        const button_arr = Array.from(buttons);
        button_arr.forEach((el) => new ToggleNavButton(el));
    },
};

export default ToggleNav;
