/* -------------------------------------------------------------------------- *
 * Modal Window for YouTube Embeds *
 * -------------------------------------------------------------------------- */

let modal;
let iframe;
let close_button;

function openVideoModal(youtube_id) {
    // Update the iframe source
    iframe.src = `https://www.youtube.com/embed/${youtube_id}?modestbranding=1&rel=0&autoplay=1`;

    // Show the modal window
    modal.classList.add('is-open');
}

function closeVideoModal() {
    // Stop the video from playing
    iframe.src = 'about:blank';

    // Hide the modal window
    modal.classList.remove('is-open');
}

class VideoModalButton {
    constructor(el) {
        this.el = el;
        this.youtube_id = el.dataset.v;

        this.el.addEventListener('click', (e) => {
            e.preventDefault();
            openVideoModal(this.youtube_id);
        });
    }
}

/* ------------- *
 * Module Export *
 * ------------- */

const VideoModal = {
    init() {
        const buttons = document.querySelectorAll('.js-videoBtn');
        const button_arr = Array.from(buttons);

        button_arr.forEach((el) => new VideoModalButton(el));

        modal = document.querySelector('#js-modal');
        iframe = document.querySelector('#js-modalIframe');

        close_button = document.querySelector('#js-modalClose');

        modal.addEventListener('click', closeVideoModal);
        close_button.addEventListener('click', closeVideoModal);
    },

    open(youtube_id) {
        if (modal !== undefined) {
            openVideoModal(youtube_id);
        }
    },
};

export default VideoModal;
