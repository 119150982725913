/* -------------------------------------------------------------------------- *
 * Toggle Display Buttons *
 * -------------------------------------------------------------------------- *
 * Toggles the display of a target element when clicking on a trigger element.
 * Settings and initial state are controlled by ARIA attributes and the state
 * class. Styling for both the trigger and target element should be adjusted
 * using the state class that is added to both elements on click.
 *
 * -------------------------- *
 * Trigger Element Attributes *
 * -------------------------- *
 * class="js-toggle"
 * aria-haspopup="true"
 * aria-controls="js-1"
 * aria-expanded="false"
 * data-group="1"
 *
 * ------------------------- *
 * Target Element Attributes *
 * ------------------------- *
 * id="js-1"
 * aria-hidden="true"
 */

const settings = {
    selector: '.js-toggle',
    state_class: 'is-active',
};

const groups_arr = [];

class ToggleButton {
    constructor(el) {
        // Use ARIA attributes for settings
        const aria_expanded = el.getAttribute('aria-expanded');
        const aria_controls = el.getAttribute('aria-controls');
        const button_group = el.getAttribute('data-group');

        // Object
        this.trigger_el = el;
        this.target_el = document.getElementById(aria_controls);
        this.toggled = aria_expanded === 'true';

        if (button_group !== undefined) {
            this.group = button_group;

            // Add object to group array
            groups_arr[this.group] = groups_arr[this.group] || [];
            groups_arr[this.group].push(this);
        }

        // Show/hide content on click
        this.trigger_el.addEventListener('click', (e) => {
            e.preventDefault();
            this.toggleItem();
        });
    }

    toggleItem() {
        // Check for button group
        if (this.group) {
            // Close open buttons in the same group
            groups_arr[this.group].forEach((button) => {
                if (button !== this && button.toggled === true) {
                    button.toggleItem();
                }
            });
        }

        // Update button state
        this.trigger_el.classList.toggle(settings.state_class);
        this.trigger_el.setAttribute('aria-expanded', !this.toggled);

        // Update content state
        this.target_el.classList.toggle(settings.state_class);
        this.target_el.setAttribute('aria-hidden', this.toggled);

        // Update object state
        this.toggled = !this.toggled;
    }
}

/* ------------- *
 * Module Export *
 * ------------- */

const Toggle = {
    init(selector = settings.selector) {
        const buttons = document.querySelectorAll(selector);
        const button_arr = Array.from(buttons);
        button_arr.forEach((el) => new ToggleButton(el));
    },
};

export default Toggle;
